import React from 'react';
import { Page, Section, PostPreview } from 'src/components';
import { graphql, useStaticQuery } from 'gatsby';
import { Grid } from '../components';
import { GatsbyImage } from 'gatsby-plugin-image';
import paths from 'src/constants/paths';

const QUERY = graphql`
  query InterviewsQuery {
    allGraphCmsInterview(sort: { fields: date, order: DESC }) {
      nodes {
        id
        slug
        title
        summary
        image {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 2000, aspectRatio: 1)
        }
        people {
          id
          location
          firstName
          lastName
          avatar {
            gatsbyImageData(layout: FIXED, placeholder: BLURRED, width: 100, aspectRatio: 1)
          }
        }
      }
    }
  }
`;

const CommunityPage = () => {
  const data = useStaticQuery(QUERY);
  const posts = data?.allGraphCmsInterview?.nodes;
  return (
    <Page
      path={paths.COMMUNITY}
      title="The Catch Community"
      dark
      color="#000000"
      darkColor="#000000"
    >
      <Section
        large
        creatour
        center
        dark
        wide
        top
        image="/img/og/community.png"
        bg="black"
        title="Catch Community"
        subtitle="People use Catch as a way to prioritize what matters most to them — doing the work they love, while automating the necessary (but frustrating) things that distract them from that work. "
      >
        <br />
        <div style={{ marginLeft: -100, marginRight: -100 }}>
          <Grid num={8} noResponsive>
            {posts?.slice(0, 16)?.map((post) => (
              <GatsbyImage
                key={post?.id}
                image={post?.image?.gatsbyImageData}
                style={{
                  borderRadius: 24,
                }}
              />
            ))}
          </Grid>
        </div>
      </Section>
      <Section small>
        {posts?.map((post) => (
          <PostPreview
            key={post.id}
            imageSize="Tall"
            eyebrow={post.people?.[0]?.location}
            link={'/community/' + post.slug}
            title={post.title}
            teaser={post.summary}
            authors={post.people}
            image={post.image?.gatsbyImageData}
          />
        ))}
      </Section>
    </Page>
  );
};

export default CommunityPage;
